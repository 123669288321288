
import axios from 'axios';
import { decrypt, encrypt } from 'utils/encrypt';

import { API_URL } from 'utils/environment';

export const login = async ({ loginData }) => {
  try {
    const request_body = await encrypt(loginData);
    const response = await fetch(API_URL + `auth/login?timestamp=${new Date().getTime()}`, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cache
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: request_body, // body data type must match "Content-Type" header
    });
    const data = await response.json();
    const result = await decrypt(data)
    return result;
  } catch (error) {
    //console.log("-login error", error);
  }
};
export const logout = async () => {
  const token = window.localStorage.getItem('token');
  //console.log("token", token);
  axios.defaults.withCredentials = true;
  try {
    const headers = {
      Authorization: `${token}`,
    };
    window.localStorage.clear();
    await axios.post(API_URL + `auth/logout?timestamp=${new Date().getTime()}`, {}, { headers });
  } catch (error) {
    //console.log("-logout error", error);
  }
};
