import { useEffect, useState } from "react";

import { Grid } from "@mui/material";

// Material Dashboard 2 React components
import MDBadgeDot from "components/MDBadgeDot";
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import DefaultLineChart from "examples/Charts/LineCharts/DefaultLineChart";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

// Images
import creditsImg from "assets/images/credits.png";
import devicesImg from "assets/images/devices.png";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import request from 'service/request';

const Dashboard = () => {
  const [deviceType, setDeviceType] = useState("all");
  const [devicesInfo, setDevicesInfo] = useState({
    total_count: 0,
    lastweek_count: 0,
    thisweek_count: 0,
    thisweek_data: [0, 0, 0, 0, 0, 0, 0]
  });
  const [creditsInfo, setCreditsInfo] = useState({
    total_count: 0,
    lastweek_count: 0,
    thisweek_count: 0,
    thisweek_data: [0, 0, 0, 0, 0, 0, 0]
  });

  const [xLabels, setXLabels] = useState(["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"]);

  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    document.title = "Thunder Player | Dashboard";
    let options = {};
    if (deviceType !== 'all') options = { device_type: deviceType };
    getTableData(options);
  }, [deviceType]);

  const getTableData = async (options = {}) => {
    try {
      setIsLoading(true);

      const result = await request.list({ entity: "dashboard", options });
      if (result && result.last7Days) {
        setXLabels(result.last7Days)
        setDevicesInfo(result.devices)
        setCreditsInfo(result.credits);
      }

      //console.log('%csrc/layout/dashboard.js:100 results', 'color: green;', result);
    } catch (error) {
      //console.log('%csrc/layout/dashboard.js:100 error', 'color: red;', error);
    } finally {
      setIsLoading(false);
    }
  };

  const devices = {
    labels: xLabels,
    datasets: [
      { label: "Total", color: "total", data: devicesInfo?.thisweek_data['total'], tension: 0.4 },
      { label: "Samsung", color: "samsung", data: devicesInfo?.thisweek_data['samsung'], tension: 0.4 },
      { label: "LG", color: "lg", data: devicesInfo?.thisweek_data['lg'], tension: 0.4 },
      { label: "Windows", color: "windows", data: devicesInfo?.thisweek_data['windows'], tension: 0.4 },
      { label: "Android", color: "android", data: devicesInfo?.thisweek_data['android'], tension: 0.4 },
      { label: "iOS", color: "ios", data: devicesInfo?.thisweek_data['ios'], tension: 0.4 },
      { label: "macOS", color: "macos", data: devicesInfo?.thisweek_data['macos'], tension: 0.4 },
    ],
  };
  const credits = {
    labels: xLabels,
    datasets: [{ label: "Credits", color: "info", data: creditsInfo.thisweek_data, tension: 0.3 }],
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container spacing={3} pt={2} >
          <Grid item xs={12} md={6} lg={6}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="success"
                icon={<MDBox component="img" src={devicesImg} alt="Devices" width="100%" color="white" sx={{ filter: "brightness(0) invert(1)" }} />}
                title="Devices"
                count={devicesInfo.total_count}
                percentage={{
                  color: "success",
                  amount: "+" + devicesInfo.thisweek_count,
                  label: "in this week",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="warning"
                icon={<MDBox component="img" src={creditsImg} alt="Orders" width="100%" color="white" sx={{ filter: "brightness(0) invert(1)" }} />}
                title="Credits"
                count={creditsInfo.total_count}
                percentage={{
                  color: "success",
                  amount: "+" + creditsInfo.thisweek_count,
                  label: "in this week",
                }}
              />
            </MDBox>
          </Grid>
        </Grid>
        <MDBox mt={4.5}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={6}>
              <MDBox mb={3}>
                <DefaultLineChart
                  title="Devices"
                  description={
                    <MDBox display="flex" justifyContent="space-between">
                      <MDBox display="flex" ml={-1}>
                        <MDBadgeDot color="total" size="sm" badgeContent="Total" />
                        <MDBadgeDot color="samsung" size="sm" badgeContent="Samsung" />
                        <MDBadgeDot color="lg" size="sm" badgeContent="LG" />
                        <MDBadgeDot color="windows" size="sm" badgeContent="Windows" />
                        <MDBadgeDot color="android" size="sm" badgeContent="Android" />
                        <MDBadgeDot color="ios" size="sm" badgeContent="iOS" />
                        <MDBadgeDot color="macos" size="sm" badgeContent="macOS" />
                      </MDBox>
                    </MDBox>
                  }
                  chart={devices}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <MDBox mb={3}>
                <DefaultLineChart
                  title="Credits"
                  chart={credits}
                  height="17rem"
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </DashboardLayout>
  );
}

export default Dashboard;
