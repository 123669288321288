
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// import AuthService from "services/auth-service";

// Authentication layout components
import PageLayout from "examples/LayoutContainers/PageLayout";

// Image
import bgImage from "assets/images/background.png";

import request from "service/request";

const VerifyEmail = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { darkMode } = useSelector(state => state.materialUI);
    const { token } = useParams();

    const [verificationMessage, setVerificationMessage] = useState('Invalid token');
    useEffect(() => {
        const verifyEmailToken = async () => {
            try {
                const response = await request.get({ entity: `/verify-email/${token}` });
                //console.log(response);
                setVerificationMessage(response.message);
            } catch (error) {
                console.error('Error verifying email:', error);
                setVerificationMessage('Error verifying email.');
            }
        };

        verifyEmailToken();
        document.title = "Thunder Player | Verify Email";
    }, [token]);

    const handleGoToLogin = () => {
        navigate('/login');
    };
    return (

        <PageLayout background="white">
            <Grid
                container
                sx={{
                    backgroundColor: ({ palette: { background, white } }) =>
                        darkMode ? background.default : white.main,
                }}
            >
                <Grid item xs={12} lg={6}>
                    <MDBox
                        display={{ xs: "none", lg: "flex" }}
                        width="calc(100% - 2rem)"
                        height="calc(100vh - 2rem)"
                        borderRadius="lg"
                        ml={2}
                        mt={2}
                        sx={{
                            backgroundImage: `url(${bgImage})`,
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center"
                        }}
                    />
                </Grid>
                <Grid item xs={11} sm={8} md={6} lg={4} xl={3} sx={{ mx: "auto" }}>
                    <MDBox display="flex" flexDirection="column" justifyContent="center" height="100vh">
                        <MDBox py={1} px={3} textAlign="center">
                            <MDBox mb={1} textAlign="center">
                                <MDTypography variant="h4" fontWeight="bold">
                                    Verify Email
                                </MDTypography>
                            </MDBox>
                            <MDTypography variant="body2" color="text">
                                {verificationMessage}
                            </MDTypography>
                        </MDBox>
                        <MDBox p={3}>
                            <MDBox mt={4} mb={1}>
                                <MDButton variant="gradient" color="info" size="large" fullWidth onClick={handleGoToLogin}>
                                    Go to Login
                                </MDButton>
                            </MDBox>

                        </MDBox>
                    </MDBox>
                </Grid>
            </Grid>
        </PageLayout>
    );
}

export default VerifyEmail;
