import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// @mui material components
import { ButtonGroup, Card, FormControl, Grid, Icon, InputLabel, Menu, MenuItem, Select } from "@mui/material";

import LockIcon from '@mui/icons-material/Lock';
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/CheckableTable";

import { selectDarkMode } from "redux/materialUI/selectors";

import { MoveDownOutlined } from "@mui/icons-material";
import request from 'service/request';
import { dateTimeFormat } from 'utils/helper';

import ActiveDialog from "./ActiveDialog";
import TransferDialog from "./TransferDialog";

const deviceTypeList = [
  { key: "windows", label: "Windows" },
  { key: "macos", label: "macOS" },
  { key: "android", label: "Android" },
  { key: "ios", label: "iOS" },
  { key: "samsung", label: "Samsung" },
  { key: "lg", label: "LG" }
]

const DevicesList = () => {
  const darkMode = useSelector(selectDarkMode);
  const dispatch = useDispatch();
  const [tableData, setTableData] = useState([]);
  const [tableCount, setTableCount] = useState(0);
  const [pageOption, setPageOption] = useState({
    pageIndex: 0, pageSize: 10, sortField: "created", sortOrder: "desc"
  });
  const [isLoading, setIsLoading] = useState(true);
  const [checkedRecords, setCheckedRecords] = useState([]);
  const [confirmAction, setConfirmAction] = useState({
    type: 'active',
    records: []
  });
  const [selectedDevice, setSelectedDevice] = useState({
    _id: "",
    mac_address: "",
  });
  const [search, setSearch] = useState("");
  const [deviceType, setDeviceType] = useState("all");

  const handleCheckedRecordsChange = (records) => {
    setCheckedRecords(records);
  };

  useEffect(() => {
    searchHandler();
    document.title = "Thunder Player | Devices";
  }, [pageOption]);

  const searchHandler = () => {
    let options = {};
    if (search !== "") options["search"] = search;
    if (deviceType !== "all") options["deviceType"] = deviceType;
    getTableData(options);
  }

  const [menu, setMenu] = useState(null);

  const openMenu = (event) => setMenu(event.currentTarget);
  const closeMenu = () => setMenu(null);

  const actionList = [
    "Active",
    "Transfer"
  ]
  const [selectedBulkActionIdx, setSelectedBulkActionIdx] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isTransferModalOpen, setIsTransferModalOpen] = useState(false);

  const getTableData = async (option = {}) => {
    try {
      setIsLoading(true);
      let options = { ...option, ...pageOption };

      const data = await request.list({ entity: "devices", options });
      if (data.result) {
        setTableData(data.result);
        setTableCount(data.count);
      }
      //console.log('%csrc/layout/devices.js:100 results', 'color: green;', data);
    } catch (error) {
      //console.log('%csrc/layout/devices.js:100 error', 'color: red;', error);
    } finally {
      setIsLoading(false);
    }
  };

  const itemList = [
    { label: "Active", key: 1, icon: <LockIcon /> },
    { label: "Transfer", key: 2, icon: <MoveDownOutlined /> },
  ];
  const handleMenuClick = (item, record) => {

    if (item.key === 1) {
      setConfirmAction({ type: 'active', records: [record] })
      setIsModalOpen(true);
    } else if (item.key === 2) {
      setSelectedDevice({ ...record });
      setIsTransferModalOpen(true);
    }
  };
  const getDeviceType = {
    "windows": "Windows",
    "macos": "macOS",
    "android": "Android",
    "ios": "iOS",
    "samsung": "Samsung",
    "lg": "LG"
  }
  const tableColumn = useMemo(() => [
    { Header: "mac address", accessor: "mac_address", width: "15%" },
    { Header: "version", accessor: "app_version", width: "15%" },
    { Header: "device type", accessor: "device_type", width: "15%", Cell: ({ value }) => (getDeviceType[value]) },
    { Header: "expiration", accessor: "expire_date", width: "15%", Cell: ({ value }) => (dateTimeFormat(value)) },
    { Header: "status", accessor: "active_status", width: "10%", align: "center", Cell: ({ value }) => (value ? "Active" : "-") },
    { Header: "activated at", accessor: "activated_date", width: "15%", align: "center", Cell: ({ value }) => (value ? dateTimeFormat(value) : "-") },
    { Header: "created", accessor: "created", width: "10%", Cell: ({ value }) => (dateTimeFormat(value)) },
    // {
    //   Header: "actions",
    //   disableSortBy: true,
    //   accessor: "",
    //   width: "10%",
    //   Cell: (data) => {
    //     return <ActionsCell row={data?.row?.original} itemList={itemList} handleMenuClick={handleMenuClick} />;
    //   },
    // },
  ], [itemList, handleMenuClick]);



  const renderMenu = (
    <Menu
      anchorEl={menu}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      open={Boolean(menu)}
      onClose={closeMenu}
      keepMounted
    >
      {
        actionList.map((action, index) =>
          <MenuItem key={index}
            onClick={() => {
              closeMenu();
              setSelectedBulkActionIdx(index);
              handleMenuClickBulk(index);
            }
            }>
            {action}
          </MenuItem>)
      }
    </Menu>
  );
  const handleMenuClickBulk = (index) => {
    var newIndex = index ?? selectedBulkActionIdx;
    if (newIndex === 0) {
      setConfirmAction({ type: 'active', records: [...checkedRecords] })
      setIsModalOpen(true);
    } else {
      if (checkedRecords.length > 0) {
        setSelectedDevice({ ...checkedRecords[0] });
      }
      setIsTransferModalOpen(true);
    }
  }

  const handleSubmit = async ({ macAddress, type }) => {
    setIsModalOpen(false);
    setIsLoading(true);
    const results = await request.post({ entity: "devices/active", jsonData: { mac_address: macAddress, type: type } });
    if (results) {
      await searchHandler();
    }
    setIsLoading(false);
  }
  const handleTransfer = async ({ sourceDevice, macAddress, deviceCode }) => {
    // setIsLoading(true);

    const results = await request.post({ entity: "devices/transfer", jsonData: { sourceDevice: sourceDevice._id, targetDevice: macAddress, device_code: deviceCode } });
    if (results) {
      setIsTransferModalOpen(false);
      setSelectedDevice(null);
      await searchHandler();
    }
    // setIsLoading(false);
  }
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={3}>
        <Card>
          <MDBox pt={3} px={3} lineHeight={1}>
            <MDBox display="flex" justifyContent="space-between" alignItems="center" >
              <MDTypography variant="h5" fontWeight="medium" textTransform="capitalize">
                Device List
              </MDTypography>
              <MDBox display="flex">
                <ButtonGroup variant="outlined" aria-label="Basic button group">
                  <MDButton variant={true ? "gradient" : "outlined"} sx={{ height: 45 }} color="info" onClick={() => handleMenuClickBulk(null)}>
                    {actionList[selectedBulkActionIdx]}
                  </MDButton>
                  <MDButton variant={false ? "gradient" : "outlined"} color="info"

                    size="small"
                    aria-controls={menu ? "split-button-menu" : undefined}
                    aria-expanded={menu ? "true" : undefined}
                    aria-label="select merge strategy"
                    aria-haspopup="menu" onClick={openMenu}>
                    <Icon>keyboard_arrow_down</Icon>
                  </MDButton>
                </ButtonGroup>
                &nbsp;
                {renderMenu}
              </MDBox>
            </MDBox>
            <MDBox mt={3} display="flex" justifyContent="left">
              <Grid container spacing={3}>
                <Grid item xs={12} md={6} lg={3}>
                  <MDInput
                    fullWidth
                    label="Search"
                    type="search"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    InputLabelProps={{ shrink: true, }}
                    variant="outlined"
                    placeholder=""
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={2}>
                  <FormControl fullWidth sx={{ height: 45 }}>
                    <InputLabel>Device Type</InputLabel>
                    <Select
                      name="type"
                      displayEmpty
                      label="Device Type"
                      sx={{ height: 45 }}
                      value={deviceType}
                      onChange={(e) => setDeviceType(e.target.value)}
                    >
                      <MenuItem value="all">All</MenuItem>
                      {
                        deviceTypeList.map((item) =>
                          <MenuItem value={item.key} key={item.key}>{item.label}</MenuItem>
                        )
                      }
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={1.5} justifyContent="right">
                  <MDButton fullWidth sx={{ height: 45 }} variant="gradient" color="info" icon="search" onClick={searchHandler}>
                    Search
                  </MDButton>
                </Grid>
              </Grid>
            </MDBox>
          </MDBox>
          <DataTable tableColumn={tableColumn} tableData={tableData} tableCount={tableCount} entriesPerPage={true} canSearch={false} isLoading={isLoading} onCheckedRecordsChange={handleCheckedRecordsChange} setPageOption={setPageOption} />
        </Card>
      </MDBox>
      <ActiveDialog
        open={isModalOpen}
        handleClose={() => {
          setIsModalOpen(false);
          setSelectedDevice(null);
        }}
        handleSubmit={handleSubmit}
        darkMode={darkMode}
      />
      <TransferDialog
        open={isTransferModalOpen}
        handleClose={() => {
          setIsTransferModalOpen(false);
          setSelectedDevice(null);
        }}
        sourceDevice={selectedDevice}
        handleTransfer={handleTransfer}
        darkMode={darkMode}
      />
    </DashboardLayout>
  );
}

export default DevicesList;
