import { loadStripe } from "@stripe/stripe-js";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// @mui material components
import { Grid } from "@mui/material";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";


// Material Dashboard 2 PRO React examples
import DefaultPricingCard from "examples/Cards/PricingCards/DefaultPricingCard";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import { selectDarkMode } from "redux/materialUI/selectors";
import request from 'service/request';
import { STRIPE_KEY } from "utils/environment";


const Purchase = () => {
    // const navigate = useNavigate();
    const dispatch = useDispatch();
    const darkMode = useSelector(selectDarkMode);
    const [isLoading, setIsLoading] = useState(false);

    const [selectedType, setSelectedType] = useState(0);
    const [priceList, setPriceList] = useState([
        { type: 1, label: "price of 1 credit(1yr)", price: 6.49 },
        { type: 2, label: "price of 2 credit(lifetime) ", price: 12 },
        { type: 10, label: "price of 10 credit", price: 60 },
        { type: 50, label: "price of 50 credit", price: 289 },
        { type: 100, label: "price of 100 credit", price: 549 },
        { type: 500, label: "price of 500 credit", price: 2399 },
        { type: 1000, label: "price of 1000 credit", price: 3999 },
        { type: 5000, label: "price of 5000 credit", price: 15999 },
    ]);

    const [error, setError] = useState({
        name: false,
        email: false,
        password: false,
        confirm: false,
        role: false,
        error: false,
        textError: "",
    });


    useEffect(() => {
        document.title = "Thunder Player | Credits Purchase";
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const results = await request.list({ entity: "systeminfo" });
            if (results) {
                if (results?.credit_price.length !== 0) {

                    setPriceList(results?.credit_price);
                }
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const submitHandler = async (e) => {
    }

    const handlePay = async (item) => {
        if (isLoading) return;
        // Here you will create a payment intent on your backend and get the client secret
        try {
            setSelectedType(item.type);
            setIsLoading(true);
            const response = await request.post({ entity: 'create-checkout-session', jsonData: { price: item.price, type: item.type } });
            const { id } = response.data;


            const stripe = await loadStripe(STRIPE_KEY);
            const result = await stripe.redirectToCheckout({
                sessionId: id,
            });

            if (result.error) {
                //console.log(result.error);
            }
            setIsLoading(false);
        } catch (error) {
            console.error('Error creating payment intent:', error);
            setIsLoading(false);
        }
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox mt={3} mb={9}>
                <Grid container justifyContent="center">
                    <Grid item xs={12} lg={12}>
                        <MDBox mb={10} textAlign="center">
                            <MDBox mb={1}>
                                <MDTypography variant="h3" fontWeight="bold">
                                    Buy Credits
                                </MDTypography>
                            </MDBox>
                        </MDBox>
                        <MDBox position="relative" zIndex={3} mt={8} px={{ xs: 1, sm: 0 }}>
                            <Grid container spacing={5} justifyContent="center">
                                {
                                    priceList.map((price) => {
                                        const onePrice = priceList.find((obj) => (obj.type == 1))?.price;
                                        return (<Grid key={"price" + price.type} item xs={12} lg={2.5} mb={3}>
                                            <DefaultPricingCard
                                                color={darkMode ? "dark" : "white"}
                                                badge={{ color: "success", label: price.type + " credits" }}
                                                price={{ currency: "€", value: String(price.price), type: price.type + " " }}
                                                specifications={[
                                                    { label: price.type + " Credits", includes: true },
                                                    { label: "Original Price : €" + String(price.type * onePrice), includes: true },
                                                    { label: "Discount : " + String(Math.round(100 - 100 * price.price / (price.type * onePrice))) + "%", includes: true },
                                                    { label: "Current Price : €" + String(price.price), includes: true },
                                                ]}
                                                action={{
                                                    type: "internal",
                                                    route: "/",
                                                    color: "info",
                                                    label: "Buy Now",
                                                    onClick: () => handlePay(price),
                                                    loading: isLoading && (selectedType === price.type)

                                                }}
                                            />
                                        </Grid>);

                                    })
                                }
                            </Grid>
                        </MDBox>
                    </Grid>
                </Grid>
            </MDBox>
        </DashboardLayout>
    );
}

export default Purchase;
