import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// @mui material components
import { Card, FormControl, Grid, InputLabel, Select, MenuItem as SelectMenuItem } from "@mui/material";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDDatePicker from "components/MDDatePicker";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/CheckableTable";

import { selectDarkMode } from "redux/materialUI/selectors";
import { dispatchNotification } from 'service/notificationHelper';
import { dateTimeFormat } from 'utils/helper';

import request from 'service/request';

const CreditsList = ({ pay_type = 0 }) => {
  const dispatch = useDispatch();
  const darkMode = useSelector(selectDarkMode);
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [creditsBalance, setCreditsBalance] = useState(0);

  const [tableCount, setTableCount] = useState(0);
  const [pageOption, setPageOption] = useState({
    pageIndex: 0, pageSize: 10, sortField: "created", sortOrder: "desc"
  });

  useEffect(() => {
    document.title = "Thunder Player | Credits";
    if (pay_type === "1")
      dispatchNotification("Payment was processed succesfuly", 'success');
    else if (pay_type === "2")
      dispatchNotification("Payment was not processed", 'error');
  }, []);
  useEffect(() => {
    searchHandler();
  }, [pageOption]);

  const getTableData = async (option = {}) => {
    try {
      setIsLoading(true);

      const profiles = await request.list({ entity: "profile" });
      if (profiles) {
        setCreditsBalance(profiles?.credits);
      }

      let options = { ...option, ...pageOption };

      const data = await request.list({ entity: "credits", options });
      if (data.result) {
        setTableData(data.result);
        setTableCount(data.count);
      }
    } catch (error) {
      //console.log('%csrc\layout\orders.js:100 error', 'color: red;', error);
    } finally {
      setIsLoading(false);
    }
  };
  const tableColumn = [

    { Header: "amount", accessor: "amount", width: "20%", Cell: ({ value }) => (value) },
    { Header: "type", accessor: "type", width: "20%", Cell: ({ value }) => value == 1 ? "Purchased" : "Used" },
    { Header: "Mac Address", accessor: "device.mac_address", width: "20%", Cell: ({ value }) => (value ? value : "Admin") },
    { Header: "created", accessor: "created", width: "10%", Cell: ({ value }) => (dateTimeFormat(value)) },
  ];

  const currentDate = new Date();
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [type, setType] = useState("0");
  const clearSearch = () => {
    setFromDate("");
    setToDate("");
    setType("0");
    getTableData();
  }
  const searchHandler = () => {
    let options = {};
    if (fromDate !== "") options["fromDate"] = fromDate;
    if (toDate !== "") options["toDate"] = toDate;
    if (type !== "0") options["type"] = type;
    getTableData(options);
  }

  const formatDate = (obj) => {
    const dateObj = new Date(obj);
    if (isNaN(dateObj.getTime())) {
      return '';
    }
    const year = dateObj.getFullYear();
    const month = String(dateObj.getMonth() + 1).padStart(2, '0');
    const day = String(dateObj.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  const navigate = useNavigate();
  const buyHandler = () => {
    navigate("/purchase");
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={3}>
        <Card>
          <MDBox p={3} lineHeight={1}>
            <MDBox display="flex" justifyContent="space-between">
              <MDTypography variant="h5" fontWeight="medium">
                Credits List
              </MDTypography>
              <MDBox textAlign="right" lineHeight={1.5}>
                <MDTypography variant="button" fontWeight="medium" color="info">
                  Avaiable Credits : {creditsBalance}
                </MDTypography>
              </MDBox>
            </MDBox>
            <MDBox mt={3} display="flex" justifyContent="left">
              <Grid container spacing={1}>
                <Grid item xs={12} md={6} lg={2}>
                  <MDDatePicker fullWidth type="date" label="From Date" input={{ placeholder: "yyyy-mm-dd" }} defaultValue={fromDate} onChange={(e) => setFromDate(formatDate(e))} />
                </Grid>
                <Grid item xs={12} md={6} lg={2}>
                  <MDDatePicker fullWidth type="date" label="To Date" input={{ placeholder: "yyyy-mm-dd" }} defaultValue={toDate} onChange={(e) => setToDate(formatDate(e))} />
                </Grid>
                <Grid item xs={12} md={6} lg={2}>
                  <FormControl fullWidth sx={{ height: 45 }}>
                    <InputLabel>Type</InputLabel>
                    <Select
                      label="Type"
                      name="type"
                      fullWidth
                      sx={{ height: 45 }}
                      value={type}
                      onChange={(e) => setType(e.target.value)}
                    >
                      <SelectMenuItem value="0">All</SelectMenuItem>
                      <SelectMenuItem value="1">Purchased</SelectMenuItem>
                      <SelectMenuItem value="2">Used</SelectMenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={0.3}></Grid>
                <Grid item xs={12} md={6} lg={1.5} justifyContent="right">
                  <MDButton fullWidth sx={{ height: 45 }} variant="gradient" color="info" icon="search" onClick={searchHandler}>
                    Search
                  </MDButton>
                </Grid>
                <Grid item xs={12} md={6} lg={2}></Grid>
                <Grid item xs={12} md={6} lg={2} justifyContent="right">
                  <MDButton fullWidth sx={{ height: 45 }} variant="gradient" color="warning" icon="credits" onClick={buyHandler}>
                    Buy Credits
                  </MDButton>
                </Grid>
              </Grid>
            </MDBox>
          </MDBox>
          <DataTable tableColumn={tableColumn} tableData={tableData} tableCount={tableCount} entriesPerPage={true} canSearch={false} isLoading={isLoading} setPageOption={setPageOption} />
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}

// Define propTypes for the component
CreditsList.propTypes = {
  pay_type: PropTypes.string,
};
export default CreditsList;
